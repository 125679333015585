import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2, H3 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import AppLinkBar from 'primitives/appLinkBar';

import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import HeroCover from 'components/hero/heroCover';
import Highlights from 'components/highlights';
import GalleryGrid from 'components/gallery/galleryGrid';
import BannerGradient from 'components/banner/bannerGradient';
import { AppButton } from 'primitives/appButton';

import { ShText, useShIntlLocale } from '@shoootin/translations';

import { FrontCommonMessages } from '../../../appCommonTranslations';

// @ts-ignore
// @ts-ignore
import { device, enhanceHeader } from 'helpers/helpers';

import { Sticky, StickyContainer } from 'react-sticky';
import '../frontPrestation.less';
import PrestationOthers from '../prestationOthers';
import { getStickyStyle } from '../prestationCommons';
import { FrontPhotoMessages } from './photoPageTranslations';
import {
  heroCover,
  PrestationFigures,
  prestationHighlights,
  PrestationInNav,
  PrestationProsTabSet,
} from './photoPageValues';

import PrestationTabsetPros from '../prestationPagePros';
import PrestationPageFigures from '../prestationPageFigures';
import { graphql } from 'gatsby';
import { ServicePhotoPageQuery } from '../../../appGraphQLTypes';
import { getFluidImages } from 'appGatsbyImageUtils';
import usePrestationMainImages from '../../../queries/usePrestationMainImages';

import CoverProfessionPrices from '../../../components/coverProfessionPrices';
import { useServiceProfessionPrices } from '../servicePrices';
import { AppGatsbyImage } from '../../../primitives/appImage';
import Usecase from 'components/usecase';

// don't forget to set this to correct prestation Messages.
const SpecificPageMessages = FrontPhotoMessages;

const PhotoPage = ({ data }: { data: ServicePhotoPageQuery }) => {
  console.debug('PhotoPage', data);

  const heroImage = usePrestationMainImages().photo;

  // TODO fix badly named photo benefits inputs
  const [b1, b2, b6] = getFluidImages(data, 'benefitsImages1');
  const [b3, b4, b5] = getFluidImages(data, 'benefitsImages2');
  const benefitsImages = [b1, b2, b3, b4, b5, b6];

  const professionPrices = useServiceProfessionPrices('photo', [
    'residentialRealEstate',
    'commercialRealEstate',
    'architect',
    'hotel',
    'restaurant',
  ]);

  const locale = useShIntlLocale();
  const useCase = locale === ('fr_FR' || 'fr_BE') ? 'kretz' : 'tollbrothers';

  return (
    <MainLayout className="page-prestation" pageName="prestation_photo">
      <HeroCover image={heroImage} values={heroCover} />

      <StickyContainer>
        <div className="section prestation-intro" id="intro">
          <div className="container">
            <Sticky>
              {({ style, isSticky }) => {
                const stickyStyle = getStickyStyle(isSticky);
                return (
                  <nav
                    id="linknav"
                    className={isSticky ? 'fixed' : ''}
                    style={{
                      ...style,
                      ...stickyStyle,
                    }}
                  >
                    <AppLinkBar
                      links={PrestationInNav}
                      scroll={true}
                      modifier="swiper-container-horizontal swiper-container-free-mode"
                    />
                  </nav>
                );
              }}
            </Sticky>

            {/* <ShRow>
              <ShCol sm={{ span: 40, offset: 10 }} xs={60}>
                <CoverPhotoGatsbyImage
                  fluid={data.coverIntroImage!.childImageSharp!.fluid!}
                />
              </ShCol>
            </ShRow> */}
          </div>
          <Highlights highlights={prestationHighlights} />
          <div className="cover matterport">
            <div className="cover__link" />
            <AppGatsbyImage
              className="cover__image"
              fluid={data.coverHeroImage!.childImageSharp!.fluid!}
              style={{ position: 'absolute' }}
            />
          </div>

          <PrestationPageFigures
            figures={PrestationFigures}
            images={getFluidImages(data, 'figureImages')}
            coverCaption={SpecificPageMessages.coverCaption}
          />
        </div>

        <AppSection
          id="pros"
          header={
            <H2>
              <ShText message={SpecificPageMessages.advantagesTitle} />
            </H2>
          }
        >
          <PrestationTabsetPros
            nav={PrestationProsTabSet}
            quarterMode="photo"
            images={benefitsImages}
          />
        </AppSection>

        <AppSection
          className="grid-gallery"
          id="gallery"
          header={
            <H2>
              <ShText message={FrontCommonMessages.gallery} />
            </H2>
          }
        >
          <GalleryGrid />
        </AppSection>

        <AppSection
          className="section-usecase"
          id="usecase"
          header={
            <H2>
              <ShText message={FrontCommonMessages.usecase} />
            </H2>
          }
        >
          <Usecase name={useCase} />
        </AppSection>

        <AppSection
          className="prestation-price"
          id="price"
          header={
            <H2>
              <ShText message={SpecificPageMessages.priceTitle1} />
              <br />
              <ShText message={SpecificPageMessages.priceTitle2} />
            </H2>
          }
        >
          <div key="cover-prices">
            <CoverProfessionPrices professionPrices={professionPrices} />
          </div>
        </AppSection>
      </StickyContainer>
      <BannerGradient className="banner--gradient-photo">
        <H3>
          <ShText message={SpecificPageMessages.bannerTitle} />
        </H3>
        <p>
          <ShText message={SpecificPageMessages.bannerP} />
        </p>
        <AppInternalLink to={AppInternalPaths.order}>
          <AppButton modifier={['light', 'large']}>
            <ShText message={SpecificPageMessages.bannerCta} />
          </AppButton>
        </AppInternalLink>
      </BannerGradient>

      <PrestationOthers prestationName="photo" />
    </MainLayout>
  );
};

export default PhotoPage;

export const query = graphql`
  query ServicePhotoPage {
    # TODO : remove this photos from s3
    #coverIntroImage: file(relativePath: { eq: "background/video-cover.jpg" }) {
    #  ...PrestationCoverIntroImageFragment
    #}
    # TODO wtf again? banner-matterport???
    coverHeroImage: file(
      relativePath: { eq: "background/banner-matterport.jpg" }
    ) {
      ...AppFluidHeroImageFragment
    }
    figureImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "prestation_photo-service-*.jpg" } }
    ) {
      edges {
        node {
          ...PrestationFigureImageFragment
        }
      }
    }
    benefitsImages1: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "prestation_photo-avantages-*.jpg" } }
    ) {
      edges {
        node {
          ...PrestationBenefitsImageFragment
        }
      }
    }
    benefitsImages2: allFile(
      sort: { fields: name, order: ASC }

      filter: { relativePath: { glob: "prestation_photo-avantages-*.png" } }
    ) {
      edges {
        node {
          ...PrestationBenefitsImageFragment
        }
      }
    }
  }
`;
